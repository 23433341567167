import {
  VISUALIZATION_TYPES,
  DEV_FEATURES,
  V3_METRIC_LABELS,
  V3_MEASURE_OPERATIONS,
  V3_METRIC_FIELDS,
  WIDGET_DIMENSION_FIELDS,
  DATA_SOURCE_TYPES,
  DATA_SOURCE_METADATA_SOURCE_MODEL_TITLE_KEYS,
  FEATURES,
  WIDGET_DIMENSION_LABELS,
} from 'constants/constants';

import {
  userHasDevFeatureFlag,
  userHasFeatureFlag,
} from 'services/feature-service/feature-service';
import { DATE_RANGE_KEYS } from 'utils/date/date-util';

import { GRANULARITY_TYPES } from '../widget/constants';

import messages from './sections/section-wizard.messages';
import formMessages from './steps/form.messages';
import {
  selectedSearchesSelector,
  selectedSocialSearchesSelector,
  selectedTagsSelector,
} from './widget-wizard-selectors';
import titles from './widget-wizard.messages';

const SPARK_LINE_CHART_ICON = 'sparklineChartIcon';
const DONUT_CHART_ICON = 'donutChartIcon';
const SHARE_ICON = 'share';
const MESSAGE_BUBBLE_ICON = 'messageBubble';
const SENTIMENT_FACE = 'sentimentFace';
const NEWS_ICON = 'news';
const CHART_SEO_ICON = 'chartSeo';
const LOCATION_ICON = 'location';
const LIST_ALT_ICON = 'listAlt';
const DOLLAR_SIGN = 'dollar-sign';
const MENTION_GROUP = 'mentionGroup';
const VERTICAL_BAR_CHART_ICON = 'verticalBarChartIcon';
const WORD_CLOUD_ICON = 'wordCloud';

export const METRICS = {
  primaryMetric: 'PRIMARY_METRIC',
  secondaryMetric: 'SECONDARY_METRIC',
};

export const CONTEXTS = {
  dashboards: 'DASHBOARDS',
  reports: 'REPORTS',
};

export const WIZARD_STEPS = {
  addKeyMessages: 'ADD_KEY_MESSAGES',
  addBooleanKeyMessage: 'ADD_BOOLEAN_KEY_MESSAGE',
  selectChartType: 'SELECT_CHART_TYPE',
  selectSearch: 'SELECT_SEARCH',
  selectImpactSearch: 'SELECT_IMPACT_SEARCH',
  selectSocialSearch: 'SELECT_SOCIAL_SEARCH',
  selectWidgetType: 'SELECT_WIDGET_TYPE',
  selectWidget: 'SELECT_WIDGET',
  customChartForm: 'CUSTOM_CHART_FORM',
  customChartSearchSelection: 'CUSTOM_CHART_SEARCH_SELECTION',
  customChartImpactSearchSelection: 'CUSTOM_CHART_IMPACT_SEARCH_SELECTION',
  customChartArticleTagSelection: 'CUSTOM_CHART_ARTICLE_TAG_SELECTION',
  selectMetric: 'SELECT_METRIC',
  selectTag: 'SELECT_TAG',
  selectAnalyticsAccount: 'SELECT_ANALYTICS_ACCOUNT',
  updateSearches: 'UPDATE_SEARCHES',
};

export const SIDEBAR_WIZARD_TITLES = {
  [WIZARD_STEPS.selectSearch]: titles.addSearchesTrayTitle,
  [WIZARD_STEPS.selectImpactSearch]: titles.addSearchesTrayTitle,
  [WIZARD_STEPS.customChartSearchSelection]: titles.addSearchesTrayTitle,
  [WIZARD_STEPS.customChartForm]: titles.customWidgetrayTitle,
  [WIZARD_STEPS.addKeyMessages]: titles.addKeyMessagesTrayTitle,
  [WIZARD_STEPS.selectChartType]: titles.selectChartTypeTitle,
  [WIZARD_STEPS.selectWidget]: titles.selectWidget,
  [WIZARD_STEPS.selectWidgetType]: titles.addWidgetTrayTitle,
  [WIZARD_STEPS.selectMetric]: titles.selectMetricTrayTitle,
  [WIZARD_STEPS.selectTag]: titles.addTagTrayTitle,
  [WIZARD_STEPS.selectAnalyticsAccount]: titles.selectAnalyticsAccountTrayTitle,
  [WIZARD_STEPS.updateSearches]: titles.updateSearchTitle,
  [WIZARD_STEPS.selectSocialSearch]: titles.addSearchesTrayTitle,
};

export const KEY_MESSAGE_IS_BEING_UPDATED = -1;

export const WIZARD_CHART_OPTIONS = intl => [
  /*
    The default dateRangeType for a newly created widget or Dashboard template is set to 30 days.
    This setting can be modified by the createV3Widget function in the dashboard-hub-reducer file
    and is also defined in the initialState of the dashboard-create-reducer.js file.
  */
  {
    title: intl.formatMessage(formMessages.wordCloudDefaultTitle),
    description: intl.formatMessage(formMessages.wordCloudDescription),
    icon: WORD_CLOUD_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.word,
          label: V3_METRIC_LABELS.word,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.wordCloud,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [],
      },
    ],
    qaAttribute: 'word-cloud-v3',
    hidden: !userHasDevFeatureFlag(DEV_FEATURES.wordCloud),
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.totalMentionsDefaultTitle),
    description: intl.formatMessage(formMessages.totalMentionsDescription),
    icon: SPARK_LINE_CHART_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.articleMention,
          label: V3_METRIC_LABELS.mentions,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.area,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.publishDate,
            label: WIDGET_DIMENSION_LABELS.search,
            granularity: { type: GRANULARITY_TYPES.DAY },
          },
        ],
      },
    ],
    qaAttribute: 'create-total-mentions-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.shareOfVoiceDefaultTitle),
    description: intl.formatMessage(formMessages.shareOfVoiceDescription),
    icon: DONUT_CHART_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.articleMention,
          label: V3_METRIC_LABELS.mentions,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.pie,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [],
      },
    ],
    qaAttribute: 'share-of-voice-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.socialAmpDefaultTitle),
    description: intl.formatMessage(formMessages.socialAmpDescription),
    icon: SHARE_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.articleMention,
          label: V3_METRIC_LABELS.socialPlatform,
          operation: V3_MEASURE_OPERATIONS.sum,
        },
        visualizationType: VISUALIZATION_TYPES.horizontalBar,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.facebook,
            label: WIDGET_DIMENSION_LABELS.facebook,
          },
          {
            field: WIDGET_DIMENSION_FIELDS.twitter,
            label: WIDGET_DIMENSION_LABELS.twitter,
          },
          {
            field: WIDGET_DIMENSION_FIELDS.pinterest,
            label: WIDGET_DIMENSION_LABELS.pinterest,
          },
          {
            field: WIDGET_DIMENSION_FIELDS.reddit,
            label: WIDGET_DIMENSION_LABELS.reddit,
          },
        ],
      },
    ],
    qaAttribute: 'social-amp-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.keyMessagesDefaultTitle),
    description: intl.formatMessage(formMessages.keyMessagesDescription),
    icon: MESSAGE_BUBBLE_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    steps: [WIZARD_STEPS.selectSearch, WIZARD_STEPS.addKeyMessages],
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.articleMention,
          label: V3_METRIC_LABELS.subSearch,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.horizontalBar,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [],
      },
    ],
    qaAttribute: 'key-messages-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.sentimentDefaultTitle),
    description: intl.formatMessage(formMessages.sentimentDescription),
    icon: SENTIMENT_FACE,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.articleMention,
          label: V3_METRIC_LABELS.mentions,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.pie,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.sentiment,
            label: WIDGET_DIMENSION_LABELS.sentiment,
          },
        ],
      },
    ],
    qaAttribute: 'sentiment-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.tagWidgetTitle),
    description: intl.formatMessage(formMessages.tagWidgetDescription),
    icon: NEWS_ICON,
    visualizationType: VISUALIZATION_TYPES.articleList,
    nextStep: WIZARD_STEPS.selectTag,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.userTags,
          label: V3_METRIC_LABELS.tagWidgetV3,
          operation: V3_MEASURE_OPERATIONS.top,
        },
        visualizationType: VISUALIZATION_TYPES.articleList,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            maxBuckets: 3,
            field: WIDGET_DIMENSION_FIELDS.publishDate,
            label: WIDGET_DIMENSION_LABELS.tag,
          },
        ],
      },
    ],
    qaAttribute: 'tag-widget-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.domainAuthorityTitle),
    description: intl.formatMessage(formMessages.domainAuthorityDescription),
    icon: CHART_SEO_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.articleMention,
          label: V3_METRIC_LABELS.seoImpactBucket,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.pie,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.domainAuthority,
            label: WIDGET_DIMENSION_LABELS.impactScore,
          },
        ],
      },
    ],
    qaAttribute: 'domain-authority-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.internationalDefaultTitle),
    description: intl.formatMessage(formMessages.internationalDescription),
    icon: LOCATION_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.articleMention,
          label: V3_METRIC_LABELS.mentions,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.map,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.country,
            label: WIDGET_DIMENSION_LABELS.country,
            granularity: { type: null },
          },
        ],
      },
    ],
    qaAttribute: 'international-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.stateProvinceDefaultTitle),
    description: intl.formatMessage(formMessages.stateProvinceDescription),
    icon: LOCATION_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.articleMention,
          label: V3_METRIC_LABELS.mentions,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.horizontalBar,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.state,
            label: WIDGET_DIMENSION_LABELS.stateProvince,
            granularity: { type: null },
          },
        ],
      },
    ],
    qaAttribute: 'state-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.cityDefaultTitle),
    description: intl.formatMessage(formMessages.cityDefaultDescription),
    icon: LOCATION_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.articleMention,
          label: V3_METRIC_LABELS.mentions,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.horizontalBar,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.city,
            label: WIDGET_DIMENSION_LABELS.city,
            granularity: { type: null },
          },
        ],
      },
    ],
    qaAttribute: 'city-v3',
    hidden: !userHasDevFeatureFlag(DEV_FEATURES.cityWidgetV3),
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.prTrafficDefaultTitle),
    description: intl.formatMessage(formMessages.prTrafficDefaultDescription),
    icon: SPARK_LINE_CHART_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    steps: [WIZARD_STEPS.selectSearch, WIZARD_STEPS.selectAnalyticsAccount],
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.uniquePrArticles,
          label: V3_METRIC_FIELDS.uniquePrArticles,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.line,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.gaDate,
            label: WIDGET_DIMENSION_LABELS.uniquePrArticles,
            granularity: { type: GRANULARITY_TYPES.DAY },
          },
        ],
      },
      {
        measure: {
          field: V3_METRIC_FIELDS.prTraffic,
          label: V3_METRIC_FIELDS.prTraffic,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.verticalBar,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.gaDate,
            label: WIDGET_DIMENSION_LABELS.prTraffic,
            granularity: { type: GRANULARITY_TYPES.DAY },
          },
        ],
      },
    ],
    qaAttribute: 'pr-traffic-v3',
    hidden: !userHasDevFeatureFlag(DEV_FEATURES.prTrafficWidgetV3),
    metricsUneditable: true,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.totalMentionsSessionsDefaultTitle),
    description: intl.formatMessage(
      formMessages.totalMentionsSessionsDescription,
    ),
    icon: SPARK_LINE_CHART_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    steps: [WIZARD_STEPS.selectSearch, WIZARD_STEPS.selectAnalyticsAccount],
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.articleMention,
          label: V3_METRIC_LABELS.mentions,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.area,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.publishDate,
            label: WIDGET_DIMENSION_LABELS.search,
            granularity: { type: GRANULARITY_TYPES.DAY },
          },
        ],
      },
      {
        measure: {
          field: V3_METRIC_FIELDS.sessions,
          label: V3_METRIC_LABELS.sessions,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.verticalBar,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.publishDate,
            label: V3_METRIC_LABELS.sessions,
            granularity: { type: GRANULARITY_TYPES.DAY },
          },
        ],
      },
    ],
    qaAttribute: 'total-mentions-total-sessions-v3',
    hidden: !userHasDevFeatureFlag(DEV_FEATURES.gaTotalMentionsV3),
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.highestReadershipDefaultTitle),
    description: intl.formatMessage(formMessages.highestReadershipDescription),
    icon: NEWS_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.highestReadership,
          label: V3_METRIC_LABELS.highestReadership,
          operation: V3_MEASURE_OPERATIONS.top,
        },
        visualizationType: VISUALIZATION_TYPES.table,
        isDateRangeCompare: false,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.publishDate,
            label: WIDGET_DIMENSION_LABELS.publishDate,
          },
        ],
      },
    ],
    visible: false,
    qaAttribute: 'highest-readership-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.aggregateReadershipDefaultTitle),
    description: intl.formatMessage(
      formMessages.aggregateReadershipDescription,
    ),
    icon: LIST_ALT_ICON,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.aggregateReadership,
          label: V3_METRIC_LABELS.aggregateReadership,
          operation: V3_MEASURE_OPERATIONS.sum,
        },
        visualizationType: VISUALIZATION_TYPES.horizontalBar,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.mediaType,
            label: WIDGET_DIMENSION_LABELS.mediaType,
          },
        ],
      },
    ],
    qaAttribute: 'aggregate-readership-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.adEquivalencyDefaultTitle),
    description: intl.formatMessage(formMessages.adEquivalencyDescription),
    icon: DOLLAR_SIGN,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.adEquivalency,
          label: V3_METRIC_LABELS.adEquivalency,
          operation: V3_MEASURE_OPERATIONS.sum,
        },
        visualizationType: VISUALIZATION_TYPES.horizontalBar,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.mediaType,
            label: WIDGET_DIMENSION_LABELS.mediaType,
          },
        ],
      },
    ],
    qaAttribute: 'ad-equiv-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(
      formMessages.verifiedDailyUniqueViewersDefaultTitle,
    ),
    description: intl.formatMessage(
      formMessages.verifiedDailyUniqueViewersDescription,
    ),
    icon: SPARK_LINE_CHART_ICON,
    nextStep: WIZARD_STEPS.selectImpactSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.impactSearch,
          label: V3_METRIC_LABELS.dailyUniqueViews,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.area,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        dateRange: { trailingDays: 90, isTrailing: true },
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.publishDate,
            label: WIDGET_DIMENSION_LABELS.search,
            granularity: { type: GRANULARITY_TYPES.DAY },
          },
        ],
      },
    ],
    qaAttribute: 'create-verified-unique-viewers-v3',
    hidden: !userHasFeatureFlag(FEATURES.earnedImpact),
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.conversionsDefaultTitle),
    description: intl.formatMessage(formMessages.conversionsDescription),
    icon: SPARK_LINE_CHART_ICON,
    nextStep: WIZARD_STEPS.selectImpactSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.impactSearch,
          label: V3_METRIC_LABELS.conversions,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.area,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        dateRange: { trailingDays: 90, isTrailing: true },
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.publishDate,
            label: WIDGET_DIMENSION_LABELS.search,
            granularity: { type: GRANULARITY_TYPES.DAY },
            maxBuckets: 5,
          },
        ],
      },
    ],
    qaAttribute: 'create-impact-trending-conversions',
    hidden: !userHasFeatureFlag(FEATURES.earnedImpact),
    metricsUneditable: false,
    isNewWidget: true,
  },
  {
    title: intl.formatMessage(formMessages.topPublishersTitle),
    description: intl.formatMessage(formMessages.topPublishersDescription),
    icon: MENTION_GROUP,
    nextStep: WIZARD_STEPS.selectSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.impactScore,
          label: V3_METRIC_LABELS.impactScore,
          operation: V3_MEASURE_OPERATIONS.average,
        },
        visualizationType: VISUALIZATION_TYPES.horizontalBar,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_90,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.publisherUrl,
            label: WIDGET_DIMENSION_LABELS.publisherUrl,
            maxBuckets: 5,
            additionalFields: 'publisher',
          },
        ],
      },
    ],
    qaAttribute: 'top-publishers-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.socialSearcDefaultTitle),
    description: intl.formatMessage(formMessages.socialSearchDescription),
    icon: SPARK_LINE_CHART_ICON,
    nextStep: WIZARD_STEPS.selectSocialSearch,
    metrics: [
      {
        measure: {
          field: V3_METRIC_FIELDS.totalPosts,
          label: V3_METRIC_LABELS.posts,
          operation: V3_MEASURE_OPERATIONS.count,
        },
        visualizationType: VISUALIZATION_TYPES.area,
        dateRangeType: DATE_RANGE_KEYS.TRAILING_30,
        isDateRangeCompare: false,
        dataSources: [],
        dimensions: [
          {
            field: WIDGET_DIMENSION_FIELDS.publishDate,
            label: WIDGET_DIMENSION_LABELS.twitter,
            granularity: { type: GRANULARITY_TYPES.DAY },
          },
        ],
      },
    ],
    qaAttribute: 'social-search-widget-v3',
    hidden:
      !userHasFeatureFlag(FEATURES.socialMonitoring) &&
      !userHasDevFeatureFlag(DEV_FEATURES.socialMonitoringIntegration),
    metricsUneditable: false,
    isNewWidget: false,
  },
  {
    title: intl.formatMessage(formMessages.customDefaultTitle),
    description: intl.formatMessage(formMessages.customDescription),
    icon: VERTICAL_BAR_CHART_ICON,
    metrics: [],
    nextStep: WIZARD_STEPS.customChartForm,
    qaAttribute: 'create-custom-v3',
    hidden: false,
    metricsUneditable: false,
    isNewWidget: false,
  },
];

// ******************************
// Widget helper methods.
//
export const checkForMetricSingleDimension = (metric, dimension) =>
  metric.dimensions?.length === 1 &&
  metric.dimensions.some(d => d.field === dimension);

export const checkForMetricSocialDimensions = metric =>
  metric.dimensions?.length === 4 &&
  metric.dimensions.some(d => d.field === WIDGET_DIMENSION_FIELDS.facebook) &&
  metric.dimensions.some(d => d.field === WIDGET_DIMENSION_FIELDS.twitter) &&
  metric.dimensions.some(d => d.field === WIDGET_DIMENSION_FIELDS.pinterest) &&
  metric.dimensions.some(d => d.field === WIDGET_DIMENSION_FIELDS.reddit);

export const checkForMetricMeasure = (metric, measure) =>
  (metric?.field || metric?.measure?.field) === measure;

// Persist data structure of widgets keyed on measure field in order to fetch a widget definition, and so use its
// ancillary properties (e.g. hidden, metricsUneditable).
//
const WIZARD_WIDGETS_MEASURE_LOOKUP = WIZARD_CHART_OPTIONS({
  formatMessage: s => s?.defaultMessage,
}).reduce((measureLookup, widget) => {
  const measureFields = widget.metrics.reduce((accum, metric) => {
    if (metric.measure?.field) {
      accum.push(metric.measure?.field);
    }
    return accum;
  }, []);
  measureFields.forEach(field => (measureLookup[field] = widget));
  return measureLookup;
}, {});

export const getWidgetByMetricMeasure = metric =>
  WIZARD_WIDGETS_MEASURE_LOOKUP[metric?.measure?.field] || {};

export const CHART_CATEGORIES = {
  totalCount: 'TOTAL_COUNT',
  trendOverTime: 'TREND_OVER_TIME',
  multiDimensional: 'MULTI_DIMENSIONAL',
};

export const CHART_CATEGORIES_MESSAGES = {
  [CHART_CATEGORIES.totalCount]: messages.totalCountCategory,
  [CHART_CATEGORIES.trendOverTime]: messages.trendOverTimeCategory,
  [CHART_CATEGORIES.multiDimensional]: messages.multiDimensionalCategory,
};

export const DEFAULT_DATA_ITEMS_PROPERTIES_MAP = {
  selector: selectedSearchesSelector,
  titleKey:
    DATA_SOURCE_METADATA_SOURCE_MODEL_TITLE_KEYS[DATA_SOURCE_TYPES.search],
  addOrEditWizardStep: WIZARD_STEPS.customChartSearchSelection,
  subtitleMessage: messages.searchesDataSectionSubtitle,
  noItemsMessage: messages.searchesLabelButtonDataSection,
};

export const DATA_ITEMS_PROPERTIES_MAP_V3 = {
  [DATA_SOURCE_TYPES.search]: DEFAULT_DATA_ITEMS_PROPERTIES_MAP,
  [DATA_SOURCE_TYPES.socialSearch]: {
    ...DEFAULT_DATA_ITEMS_PROPERTIES_MAP,
    selector: selectedSocialSearchesSelector,
    titleKey:
      DATA_SOURCE_METADATA_SOURCE_MODEL_TITLE_KEYS[
        DATA_SOURCE_TYPES.socialSearch
      ],
  },
  [DATA_SOURCE_TYPES.impactSearch]: {
    ...DEFAULT_DATA_ITEMS_PROPERTIES_MAP,
    addOrEditWizardStep: WIZARD_STEPS.customChartImpactSearchSelection,
  },
  [DATA_SOURCE_TYPES.tag]: {
    selector: selectedTagsSelector,
    titleKey:
      DATA_SOURCE_METADATA_SOURCE_MODEL_TITLE_KEYS[DATA_SOURCE_TYPES.tag],
    addOrEditWizardStep: WIZARD_STEPS.selectTag,
    subtitleMessage: messages.tagsDataSectionSubtitle,
    noItemsMessage: messages.tagsLabelButtonDataSection,
  },
};

export const GROUP_BY_TYPES = {
  country: 'COUNTRY',
  dataSource: 'DATA_SOURCE',
  mediaType: 'MEDIATYPE',
  outlet: 'PUBLISHER_URL',
  article: 'ARTICLE', // only valid for v2
  sentiment: 'SENTIMENT_VALUE',
  domainAuthority: 'IMPACT_SCORE_D',
  stateProvince: 'STATE',
  city: 'CITY',
  publishDate: 'PUBLISH_DATE',
  social: 'SOCIAL',
  gaDate: 'GA_DATE',
  posts: 'POSTS',
};

export const GROUP_BY_TO_VISUALIZATION_TYPES = {
  [GROUP_BY_TYPES.country]: VISUALIZATION_TYPES.map,
  [GROUP_BY_TYPES.dataSource]: VISUALIZATION_TYPES.pie,
  [GROUP_BY_TYPES.posts]: VISUALIZATION_TYPES.area,
};

export const GROUP_BY_TO_DIMENSIONS = {
  [GROUP_BY_TYPES.country]: [
    {
      metricsV3Id: 'international-v3',
      field: 'country',
      label: 'COUNTRY',
      granularity: { type: null },
    },
  ],
};
export const CHART_TYPES_MAP = intl => {
  return [
    {
      visualizationType: VISUALIZATION_TYPES.wordCloud,
      label: intl.formatMessage(messages.dropdownsWordCloud),
      icon: WORD_CLOUD_ICON,
      categories: [CHART_CATEGORIES.totalCount],
      active: true,
      activeV3: userHasDevFeatureFlag(DEV_FEATURES.wordCloud),
    },
    {
      visualizationType: VISUALIZATION_TYPES.area,
      label: intl.formatMessage(messages.dropdownsChartArea),
      icon: 'chart-area',
      categories: [CHART_CATEGORIES.trendOverTime],
      active: false,
      activeV3: true,
    },
    {
      visualizationType: VISUALIZATION_TYPES.pie,
      label: intl.formatMessage(messages.dropdownsChartDonut),
      icon: 'chart-donut',
      categories: [CHART_CATEGORIES.totalCount], // set as array in  case it belongs to many categories
      active: true,
      activeV3: true,
    },
    {
      visualizationType: VISUALIZATION_TYPES.timeSeries,
      label: intl.formatMessage(messages.dropdownsChartLine),
      icon: 'chart-sparkline',
      categories: [CHART_CATEGORIES.trendOverTime],
      active: true,
      activeV3: false,
    },
    {
      visualizationType: VISUALIZATION_TYPES.line,
      label: intl.formatMessage(messages.dropdownsChartLine),
      icon: 'chart-sparkline',
      categories: [CHART_CATEGORIES.trendOverTime],
      active: false,
      activeV3: true,
    },
    {
      visualizationType: VISUALIZATION_TYPES.horizontalBar,
      label: intl.formatMessage(messages.dropdownsHorizontalBar),
      icon: 'chart-bars-horz',
      categories: [CHART_CATEGORIES.totalCount],
      active: true,
      activeV3: true,
    },
    {
      visualizationType: VISUALIZATION_TYPES.verticalBar,
      label: intl.formatMessage(messages.dropdownsVerticalBar),
      icon: 'chart-bars-vert',
      categories: [CHART_CATEGORIES.trendOverTime],
      active: false,
      activeV3: true,
    },
    {
      visualizationType: VISUALIZATION_TYPES.map,
      label: intl.formatMessage(messages.dropdownsMap),
      icon: LOCATION_ICON,
      categories: [CHART_CATEGORIES.totalCount],
      active: true,
      activeV3: true,
    },
    {
      visualizationType: VISUALIZATION_TYPES.table, //we should remove this object when we're ready to merge table and article_list, at the moment I'll leave to avoid breaking the page
      label: intl.formatMessage(messages.dropdownsArticleList),
      icon: 'media-list',
      categories: [CHART_CATEGORIES.totalCount],
      active: true,
      activeV3: false,
    },
    {
      visualizationType: VISUALIZATION_TYPES.articleList,
      label: intl.formatMessage(messages.dropdownsArticleList),
      icon: 'media-list',
      categories: [CHART_CATEGORIES.totalCount],
      active: false,
      activeV3: true,
    },
  ];
};

export const DIMENSION_GROUP_BY_MAP = intl => {
  return [
    {
      id: GROUP_BY_TYPES.dataSource,
      dimensionFields: [WIDGET_DIMENSION_FIELDS.publishDate],
      groupByLabel: intl.formatMessage(messages.groupBySearches),
    },
    {
      id: GROUP_BY_TYPES.posts,
      dimensionFields: [WIDGET_DIMENSION_FIELDS.publishDate],
      groupByLabel: intl.formatMessage(messages.groupBySocialSearchPostsLabel),
    },
    {
      id: GROUP_BY_TYPES.publishDate,
      dimensionFields: [WIDGET_DIMENSION_FIELDS.publishDate],
      groupByLabel: intl.formatMessage(messages.groupByPublishDateLabel),
    },
    {
      id: GROUP_BY_TYPES.domainAuthority,
      dimensionFields: [WIDGET_DIMENSION_FIELDS.domainAuthority],
      groupByLabel: intl.formatMessage(messages.groupByDomainAuthorityLabel),
    },
    {
      id: GROUP_BY_TYPES.country,
      dimensionFields: [WIDGET_DIMENSION_FIELDS.country],
      groupByLabel: intl.formatMessage(messages.groupByCountryLabel),
    },
    {
      id: GROUP_BY_TYPES.stateProvince,
      dimensionFields: [WIDGET_DIMENSION_FIELDS.state],
      groupByLabel: intl.formatMessage(messages.groupByStateProvinceLabel),
    },
    ...(userHasDevFeatureFlag(DEV_FEATURES.cityWidgetV3)
      ? [
          {
            id: GROUP_BY_TYPES.city,
            dimensionFields: [WIDGET_DIMENSION_FIELDS.city],
            groupByLabel: intl.formatMessage(messages.groupByCityLabel),
          },
        ]
      : []),
    {
      id: GROUP_BY_TYPES.mediaType,
      dimensionFields: [WIDGET_DIMENSION_FIELDS.mediaType],
      groupByLabel: intl.formatMessage(messages.groupByMediaTypeLabel),
    },
    {
      id: GROUP_BY_TYPES.social,
      dimensionFields: [
        WIDGET_DIMENSION_FIELDS.facebook,
        WIDGET_DIMENSION_FIELDS.twitter,
        WIDGET_DIMENSION_FIELDS.pinterest,
        WIDGET_DIMENSION_FIELDS.reddit,
      ],
      groupByLabel: intl.formatMessage(messages.groupBySocialLabel),
    },
    {
      id: GROUP_BY_TYPES.sentiment,
      dimensionFields: [WIDGET_DIMENSION_FIELDS.sentiment],
      groupByLabel: intl.formatMessage(messages.groupBySentimentLabel),
    },
    {
      id: GROUP_BY_TYPES.outlet,
      dimensionFields: [WIDGET_DIMENSION_FIELDS.publisherUrl],
      groupByLabel: intl.formatMessage(messages.groupByOutletLabel),
    },
    {
      id: GROUP_BY_TYPES.gaDate,
      dimensionFields: [WIDGET_DIMENSION_FIELDS.gaDate],
      groupByLabel: intl.formatMessage(messages.groupBySearches),
    },
  ];
};

export const METRIC_MEASURE_MAP = (intl, hasKeyMessages) => {
  return [
    {
      measureField: V3_METRIC_FIELDS.word,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_LABELS.word,
      label: intl.formatMessage(messages.dropdownsWordCloud),
      selectable: userHasDevFeatureFlag(DEV_FEATURES.wordCloud),
      qaAttribute: 'word-cloud-metric-selection-v3',
      description: intl.formatMessage(formMessages.wordCloudDescription),
    },
    {
      measureField: V3_METRIC_FIELDS.totalPosts,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_LABELS.posts,
      label: intl.formatMessage(messages.dropdownSocialSearch),
      selectable:
        userHasFeatureFlag(FEATURES.socialMonitoring) &&
        userHasDevFeatureFlag(DEV_FEATURES.socialMonitoringIntegration),
      qaAttribute: 'total-posts-metric-selection-v3',
      description: intl.formatMessage(formMessages.socialSearchDescription),
      dimensions: [
        {
          field: WIDGET_DIMENSION_FIELDS.publishDate,
          label: 'TWITTER',
          granularity: { type: GRANULARITY_TYPES.DAY },
        },
      ],
    },
    {
      measureField: V3_METRIC_FIELDS.articleMention,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_LABELS.socialPlatform,
      label: intl.formatMessage(messages.dropdownSocialAmp),
      selectable: false,
      qaAttribute: 'social-amp-metric-selection-v3',
      description: intl.formatMessage(formMessages.socialAmpDescription),
    },
    {
      measureField: V3_METRIC_FIELDS.articleMention,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_LABELS.subSearch,
      label: intl.formatMessage(messages.dropdownMetricEarned),
      selectable: false,
      qaAttribute: 'sub-search-metric-selection-v3',
      description: intl.formatMessage(formMessages.keyMessagesDescription),
    },
    {
      measureField: V3_METRIC_FIELDS.articleMention,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_LABELS.mentions,
      label: intl.formatMessage(messages.dropdownMetricEarned),
      selectable: true,
      qaAttribute: 'earned-media-mention-metric-selection-v3',
      description: intl.formatMessage(formMessages.totalMentionsDescription),
    },
    {
      measureField: V3_METRIC_FIELDS.totalReadership,
      measureOperation: V3_MEASURE_OPERATIONS.sum,
      measureLabel: hasKeyMessages
        ? V3_METRIC_LABELS.subSearch
        : V3_METRIC_LABELS.totalReadership,
      label: intl.formatMessage(messages.dropdownsTotalReadership),
      selectable: userHasDevFeatureFlag(
        DEV_FEATURES.totalReadershipCustomWidget,
      ),
      qaAttribute: 'WEpabA23FcfTXqw2xmZnr',
      description: intl.formatMessage(
        messages.dropdownsTotalReadershipDescription,
      ),
    },
    {
      measureField: V3_METRIC_FIELDS.mobileReadership,
      measureOperation: V3_MEASURE_OPERATIONS.sum,
      measureLabel: hasKeyMessages
        ? V3_METRIC_LABELS.subSearch
        : V3_METRIC_LABELS.mobileReadership,
      label: intl.formatMessage(messages.dropdownsMobileReadership),
      selectable: true,
      qaAttribute: 'mobile-readership-metric-selection-v3',
      description: intl.formatMessage(
        messages.dropdownsMobileReadershipDescription,
      ),
    },
    {
      measureField: V3_METRIC_FIELDS.desktopReadership,
      measureOperation: V3_MEASURE_OPERATIONS.sum,
      measureLabel: hasKeyMessages
        ? V3_METRIC_LABELS.subSearch
        : V3_METRIC_LABELS.desktopReadership,
      label: intl.formatMessage(messages.dropdownsDesktopReadership),
      selectable: true,
      qaAttribute: 'desktop-readership-metric-selection-v3',
      description: intl.formatMessage(
        messages.dropdownsDesktopReadershipDescription,
      ),
    },
    {
      measureField: V3_METRIC_FIELDS.sessions,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_LABELS.sessions,
      label: intl.formatMessage(messages.dropdownTotalSessions),
      qaAttribute: 'total-sessions-metric-selection-v3',
      description: intl.formatMessage(
        messages.dropdownTotalSessionsDescription,
      ),
      dimensions: [
        {
          field: WIDGET_DIMENSION_FIELDS.publishDate,
          label: V3_METRIC_LABELS.sessions,
          granularity: { type: GRANULARITY_TYPES.DAY },
        },
      ],
      selectable: userHasDevFeatureFlag(DEV_FEATURES.gaTotalMentionsV3),
    },
    {
      measureField: V3_METRIC_FIELDS.userTags,
      measureOperation: V3_MEASURE_OPERATIONS.top,
      measureLabel: V3_METRIC_LABELS.tagWidgetV3,
      label: intl.formatMessage(messages.dropdownTag),
      selectable: true,
      qaAttribute: 'tag-metric-selection-v3',
      description: intl.formatMessage(messages.dropdownTagDescription),
    },
    {
      measureField: V3_METRIC_FIELDS.impactSearch,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_LABELS.dailyUniqueViews,
      label: intl.formatMessage(
        formMessages.verifiedDailyUniqueViewersDefaultTitle,
      ),
      selectable: userHasFeatureFlag(FEATURES.earnedImpact),
      qaAttribute: 'daily-unique-views-metric-selection-v3',
      description: intl.formatMessage(
        formMessages.verifiedDailyUniqueViewersDescription,
      ),
      dimensions: [
        {
          field: WIDGET_DIMENSION_FIELDS.publishDate,
          label: WIDGET_DIMENSION_LABELS.search,
          granularity: { type: 'DAY' },
        },
      ],
    },
    {
      measureField: V3_METRIC_FIELDS.impactSearch,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_LABELS.conversions,
      label: intl.formatMessage(formMessages.conversionsDefaultTitle),
      selectable: userHasFeatureFlag(FEATURES.earnedImpact),
      qaAttribute: 'total-conversions-selection-v3',
      description: intl.formatMessage(formMessages.conversionsDescription),
      dimensions: [
        {
          field: WIDGET_DIMENSION_FIELDS.publishDate,
          label: WIDGET_DIMENSION_LABELS.search,
          granularity: { type: 'DAY' },
        },
      ],
    },
    {
      measureField: V3_METRIC_FIELDS.adEquivalency,
      measureOperation: V3_MEASURE_OPERATIONS.sum,
      measureLabel: V3_METRIC_LABELS.adEquivalency,
      label: intl.formatMessage(messages.dropdownAVE),
      selectable: false,
    },
    {
      measureField: V3_METRIC_FIELDS.aggregateReadership,
      measureOperation: V3_MEASURE_OPERATIONS.sum,
      measureLabel: V3_METRIC_LABELS.aggregateReadership,
      label: intl.formatMessage(messages.dropdownAggregateReadership),
      selectable: false,
    },
    {
      measureField: V3_METRIC_FIELDS.highestReadership,
      measureOperation: V3_MEASURE_OPERATIONS.top,
      measureLabel: V3_METRIC_LABELS.highestReadership,
      label: intl.formatMessage(messages.dropdownHighestReadership),
      selectable: false,
    },
    {
      measureField: V3_METRIC_FIELDS.impactScore,
      measureOperation: V3_MEASURE_OPERATIONS.average,
      measureLabel: V3_METRIC_LABELS.impactScore,
      label: intl.formatMessage(messages.dropdownImpactScore),
      selectable: false,
    },
    {
      measureField: V3_METRIC_FIELDS.uniquePrArticles,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_FIELDS.uniquePrArticles,
      label: intl.formatMessage(messages.uniquePrArticlesMetricLabel),
      selectable: false,
    },
    {
      measureField: V3_METRIC_FIELDS.prTraffic,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_FIELDS.prTraffic,
      label: intl.formatMessage(messages.prTrafficMetricLabel),
      selectable: false,
    },
    {
      measureField: V3_METRIC_FIELDS.articleMention,
      measureOperation: V3_MEASURE_OPERATIONS.count,
      measureLabel: V3_METRIC_FIELDS.seoImpactBucket,
      label: intl.formatMessage(messages.dropdownMetricEarned),
      selectable: false,
    },
  ];
};

export const DEFAULT_METRICS_VISUALIZATION_TYPES = {
  [V3_METRIC_FIELDS.userTags]: VISUALIZATION_TYPES.articleList,
};

export const SEARCHES_FILTER_SHARED_WITH_ME = 'SELECT_SHARED_WITH_ME';
export const SEARCHES_FILTER_ALL_SEARCHES = 'SELECT_ALL_SEARCHES';
export const SEARCHES_FILTER_MY_SEARCHES = 'SELECT_MY_SEARCHES';

export const SEARCHES_FILTER_MAP = intl => [
  {
    id: SEARCHES_FILTER_ALL_SEARCHES,
    label: intl.formatMessage(formMessages.filterAllSearches),
  },
  {
    id: SEARCHES_FILTER_MY_SEARCHES,
    label: intl.formatMessage(formMessages.filterMySearches),
  },
  {
    id: SEARCHES_FILTER_SHARED_WITH_ME,
    label: intl.formatMessage(formMessages.filterSharedWithMe),
  },
];

export const SELECT_ALL_TAGS = 'SELECT_ALL_TAGS';
export const SELECT_OWNED_TAGS = 'SELECT_OWNED_TAGS';
export const SELECT_TAGS_SHARED_WITH_ME = 'SELECT_TAGS_SHARED_WITH_ME';

export const TAGS_FILTER_MAP = intl => [
  {
    id: SELECT_ALL_TAGS,
    label: intl.formatMessage(formMessages.filterAllTags),
  },
  {
    id: SELECT_OWNED_TAGS,
    label: intl.formatMessage(formMessages.filterMyTags),
  },
  {
    id: SELECT_TAGS_SHARED_WITH_ME,
    label: intl.formatMessage(formMessages.filterSharedWithMe),
  },
];

export const DATE_RANGE_COMPARE_TYPES = {
  previousYear: 'PREVIOUS_YEAR',
  previousPeriod: 'PREVIOUS_PERIOD',
  customStartDate: 'CUSTOM',
};

export const DATE_RANGE_COMPARE_TYPES_MESSAGES = {
  [DATE_RANGE_COMPARE_TYPES.previousYear]: messages.labelPreviousYearDateRange,
  [DATE_RANGE_COMPARE_TYPES.previousPeriod]:
    messages.labelPreviousPeriodDateRange,
  [DATE_RANGE_COMPARE_TYPES.customStartDate]: messages.labelCustomDateRange,
};

export const GROUP_BY_MAP_V3 = intl => [
  {
    id: 'DATA_SOURCE',
    label: intl.formatMessage(messages.groupBySearches),
    active: true,
  },
  {
    id: 'MEDIATYPE',
    label: intl.formatMessage(messages.groupByMediaTypeLabel),
    active: true,
  },
  {
    id: 'PUBLISHER_URL',
    label: intl.formatMessage(messages.groupByOutletLabel),
    active: true,
  },
  {
    id: 'SENTIMENT_VALUE',
    label: intl.formatMessage(messages.groupBySentimentLabel),
    active: true,
  },
  {
    id: 'IMPACT_SCORE_D',
    label: intl.formatMessage(messages.groupByDomainAuthorityLabel),
    active: true,
  },
  {
    id: 'COUNTRY',
    label: intl.formatMessage(messages.groupByCountryLabel),
    active: true,
  },
  {
    id: 'STATE',
    label: intl.formatMessage(messages.groupByStateProvinceLabel),
    active: true,
  },
  {
    id: 'CITY',
    label: intl.formatMessage(messages.groupByCityLabel),
    active: false,
  },
  {
    id: 'PUBLISH_DATE',
    label: intl.formatMessage(messages.groupByPublishDateLabel),
    active: true,
  },
];

export const GROUP_BY_MAP = intl => [
  {
    id: 'DATA_SOURCE',
    label: intl.formatMessage(messages.groupBySearches),
    active: true,
  },
  {
    id: 'MEDIA_TYPE',
    label: intl.formatMessage(messages.groupByMediaTypeLabel),
    active: true,
  },
  {
    id: 'OUTLET',
    label: intl.formatMessage(messages.groupByOutletLabel),
    active: true,
  },
  {
    id: 'ARTICLE',
    label: intl.formatMessage(messages.groupByArticleLabel),
    active: true,
  },
  {
    id: 'SENTIMENT',
    label: intl.formatMessage(messages.groupBySentimentLabel),
    active: true,
  },
  {
    id: 'DOMAIN_AUTHORITY',
    label: intl.formatMessage(messages.groupByDomainAuthorityLabel),
    active: false,
  },
  {
    id: 'COUNTRY',
    label: intl.formatMessage(messages.groupByCountryLabel),
    active: true,
  },
  {
    id: 'STATE_PROVINCE',
    label: intl.formatMessage(messages.groupByStateProvinceLabel),
    active: false,
  },
  {
    id: 'CITY',
    label: intl.formatMessage(messages.groupByCityLabel),
    active: false,
  },
];
