import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';

import {
  isTotalMentionsOverTime,
  isShareOfVoice,
  isTag,
  isImpactSearch,
  isReadershipOverTime,
  isReadershipShareOfVoice,
  isTotalPosts,
  isSocialSearch,
  isTrendingConversions,
} from 'components/widgetV3/utils';
import { GOOGLE_ANALYTICS_TYPE } from 'constants/analytics-integration';
import {
  VISUALIZATION_TYPES,
  DATA_SOURCE_TYPES,
  SEARCH_DATA_SOURCE_TYPES,
  V3_METRIC_FIELDS,
  WIDGET_DIMENSION_FIELDS,
  DATA_SOURCES,
  V3_METRIC_LABELS,
  DEV_FEATURES,
} from 'constants/constants';

import { userHasDevFeatureFlag } from 'services/feature-service/feature-service';
import { DATE_RANGE_KEYS } from 'utils/date/date-util';

import {
  GROUP_BY_TYPES,
  METRIC_MEASURE_MAP,
  DIMENSION_GROUP_BY_MAP,
  WIZARD_CHART_OPTIONS,
} from './constants';
import messages from './sections/section-wizard.messages';

export const STANDARD_SEARCH_LIMIT = 25;
export const METRIC_FIELDS_TO_CHANGE_NEW_DIMENSION = [
  V3_METRIC_FIELDS.articleMention,
  V3_METRIC_FIELDS.mobileReadership,
  V3_METRIC_FIELDS.desktopReadership,
  V3_METRIC_FIELDS.totalReadership,
  V3_METRIC_FIELDS.word,
];
const METRIC_LABELS_TO_CHANGE_NEW_DIMENSION = [
  V3_METRIC_LABELS.mentions,
  V3_METRIC_LABELS.subSearch,
  V3_METRIC_LABELS.mobileReadership,
  V3_METRIC_LABELS.desktopReadership,
  V3_METRIC_LABELS.totalReadership,
  V3_METRIC_LABELS.word,
];
export const DATE_RANGE_COMPARE_SEARCH_LIMIT = 5;

export const isHighestReadership = metric => {
  return metric.measure?.label === V3_METRIC_LABELS.highestReadership;
};

export const metricIsComplete = metric => {
  if (!metric.measure) {
    return false;
  } else if (
    !metric.measure.field ||
    !metric.measure.label ||
    !metric.measure.operation
  ) {
    return false;
  } else if (!metric.visualizationType) {
    return false;
  } else if (metricIsMissingDimension(metric)) {
    return false;
  }
  return true;
};

export const metricIsMissingDimension = metric =>
  metricRequiresDimension(metric) && !metric.dimensions?.length;

export const metricRequiresDimension = metric => {
  const dimensionsRequired = true;
  const measureField = metric.measure?.field;
  const measureLabel = metric.measure?.label;
  if (
    METRIC_FIELDS_TO_CHANGE_NEW_DIMENSION.includes(measureField) &&
    METRIC_LABELS_TO_CHANGE_NEW_DIMENSION.includes(measureLabel)
  ) {
    if (metric.visualizationType) {
      return [
        VISUALIZATION_TYPES.pie,
        VISUALIZATION_TYPES.wordCloud,
        VISUALIZATION_TYPES.horizontalBar,
      ].every(v => v !== metric.visualizationType);
    }
    return false;
  }
  return dimensionsRequired;
};

export const isTagMetric = metric =>
  metric?.measure?.field === V3_METRIC_FIELDS.userTags;

export const isCompleteTagMetric = metric =>
  isTagMetric(metric) && !!metric?.visualizationType;

export const getMetricLabel = (measure, intl) => {
  if (measure?.label === V3_METRIC_LABELS.subSearch) {
    switch (measure?.field) {
      case V3_METRIC_FIELDS.totalReadership:
        return intl.formatMessage(messages.dropdownsTotalReadership);
      case V3_METRIC_FIELDS.mobileReadership:
        return intl.formatMessage(messages.dropdownsMobileReadership);
      case V3_METRIC_FIELDS.desktopReadership:
        return intl.formatMessage(messages.dropdownsDesktopReadership);
      default:
        break;
    }
  }

  const currentMetric = METRIC_MEASURE_MAP(intl).find(
    metric =>
      metric.measureField === measure.field &&
      metric.measureLabel === measure.label,
  );
  if (currentMetric) {
    return currentMetric.label;
  }
};

export const isKeyMessages = data => {
  return data?.[0]?.measure?.label === V3_METRIC_LABELS.subSearch;
};

export const isSearch = data => data?.[0]?.label !== V3_METRIC_LABELS.subSearch;

export const isSocialSearches = (primaryMetric, secondaryMetric) => {
  const metrics = compact([primaryMetric, secondaryMetric]);
  return isTotalPosts(metrics);
};

export const getV3DataLimit = (
  primaryMetric,
  secondaryMetric,
  dateRangeCompareType = null,
) => {
  let limit = 1;
  const metrics = compact([primaryMetric, secondaryMetric]);
  if (!metrics.length) {
    return limit;
  }
  if (isTrendingConversions(metrics)) {
    limit = DATE_RANGE_COMPARE_SEARCH_LIMIT;
  } else if (metrics.length > 1 && !metrics.some(m => m.dateRangeCompareType)) {
    limit = 1;
  } else if (
    isTotalMentionsOverTime(metrics) ||
    isReadershipOverTime(metrics) ||
    isReadershipShareOfVoice(metrics)
    // need to check the readership widgets as well
  ) {
    limit = metrics[0].dateRangeCompareType
      ? DATE_RANGE_COMPARE_SEARCH_LIMIT
      : STANDARD_SEARCH_LIMIT;
  } else if (isShareOfVoice(metrics)) {
    limit = dateRangeCompareType
      ? Math.ceil(STANDARD_SEARCH_LIMIT / 2)
      : STANDARD_SEARCH_LIMIT;
  } else if (isSocialSearches(metrics)) {
    limit = 1;
  }

  return limit;
};

export const getDateRangeCompareAllowedV3 = ({
  primaryMetric,
  secondaryMetric,
  dataItemsCount,
}) => {
  const metrics = compact([primaryMetric, secondaryMetric]);
  const isSOV = isShareOfVoice(metrics);
  const hasReachedMaxItems = isSOV
    ? dataItemsCount > Math.ceil(STANDARD_SEARCH_LIMIT / 2)
    : dataItemsCount > DATE_RANGE_COMPARE_SEARCH_LIMIT;

  if (!metrics.length || hasReachedMaxItems || isTag(metrics)) {
    return false;
  }

  return isSOV || isTotalMentionsOverTime(metrics);
};

export const getNewDimensionFromChartUpdate = (
  visualizationType,
  selectedVisualizationType,
  metric,
) => {
  const measureField = metric.measure?.field;
  const measureLabel = metric.measure?.label;
  if (visualizationType === selectedVisualizationType) {
    return null;
  } else if (
    (selectedVisualizationType === VISUALIZATION_TYPES.pie ||
      selectedVisualizationType === VISUALIZATION_TYPES.horizontalBar) &&
    METRIC_FIELDS_TO_CHANGE_NEW_DIMENSION.includes(measureField) &&
    METRIC_LABELS_TO_CHANGE_NEW_DIMENSION.includes(measureLabel)
  ) {
    return [];
  } else if (
    (selectedVisualizationType === VISUALIZATION_TYPES.line ||
      selectedVisualizationType === VISUALIZATION_TYPES.area ||
      selectedVisualizationType === VISUALIZATION_TYPES.verticalBar) &&
    METRIC_FIELDS_TO_CHANGE_NEW_DIMENSION.includes(measureField) &&
    METRIC_LABELS_TO_CHANGE_NEW_DIMENSION.includes(measureLabel)
  ) {
    return [
      {
        field: WIDGET_DIMENSION_FIELDS.publishDate,
        label: 'SEARCH',
        granularity: { type: 'DAY' },
      },
    ];
  }
};

const getValidVisualizationTypesByDimensions = (dimensions, intl) => {
  const metricDimensionFields = dimensions.map(x => x.field).sort();
  const gb = DIMENSION_GROUP_BY_MAP(intl).find(gb =>
    isEqual(gb.dimensionFields.sort(), metricDimensionFields),
  );
  if (gb) {
    switch (gb.id) {
      case GROUP_BY_TYPES.country:
        return [VISUALIZATION_TYPES.map];
      case GROUP_BY_TYPES.posts:
        return [VISUALIZATION_TYPES.area];
      case GROUP_BY_TYPES.domainAuthority:
        return [VISUALIZATION_TYPES.pie];
      case GROUP_BY_TYPES.sentiment:
        return [VISUALIZATION_TYPES.pie];
      case GROUP_BY_TYPES.mediaType:
      case GROUP_BY_TYPES.outlet:
      case GROUP_BY_TYPES.social:
      case GROUP_BY_TYPES.stateProvince:
      case GROUP_BY_TYPES.city:
        return [VISUALIZATION_TYPES.horizontalBar];
      default:
        return [VISUALIZATION_TYPES.area, VISUALIZATION_TYPES.pie];
    }
  } else {
    return [VISUALIZATION_TYPES.area, VISUALIZATION_TYPES.pie];
  }
};

export const getV3ValidVisualizationTypes = ({
  activeMetric,
  nonActiveMetric,
  hasKeyMessages,
  intl,
}) => {
  if (nonActiveMetric) {
    let options = [
      VISUALIZATION_TYPES.line,
      VISUALIZATION_TYPES.verticalBar,
      VISUALIZATION_TYPES.area,
    ];
    if (nonActiveMetric.visualizationType) {
      options = options.filter(
        option => option !== nonActiveMetric.visualizationType,
      );
    }
    return options;
  } else {
    if (hasKeyMessages) {
      return [VISUALIZATION_TYPES.area, VISUALIZATION_TYPES.horizontalBar];
    }
    if (activeMetric.measure.field === V3_METRIC_FIELDS.impactSearch) {
      return [VISUALIZATION_TYPES.area];
    }
    // should use userTag check
    if (
      activeMetric.measure.field === V3_METRIC_FIELDS.userTags ||
      isHighestReadership(activeMetric)
    ) {
      return [VISUALIZATION_TYPES.articleList];
    }
    if (activeMetric.measure.field === V3_METRIC_FIELDS.sessions) {
      // this is because we do not have standalone vertical bar or line yet
      return [VISUALIZATION_TYPES.area];
    }
    if (activeMetric.measure.field === V3_METRIC_FIELDS.totalPosts) {
      return [VISUALIZATION_TYPES.area];
    }

    if (activeMetric.measure.field === V3_METRIC_FIELDS.word) {
      return [VISUALIZATION_TYPES.wordCloud];
    }
    return getValidVisualizationTypesByDimensions(
      activeMetric.dimensions,
      intl,
    );
  }
};

export const getDimensionGranularity = (dimensionField, metric) => {
  if (dimensionField === WIDGET_DIMENSION_FIELDS.publishDate) {
    if (METRIC_FIELDS_TO_CHANGE_NEW_DIMENSION.includes(metric.measure.field)) {
      return { type: 'DAY' };
    } else if (isHighestReadership(metric)) {
      return { type: 'YEAR' };
    }
  }
};

export const getDimensionAdditionalFields = dimensionField => {
  if (dimensionField === 'publisher_url') {
    return 'publisher';
  }
};

export const getDimensionLabel = (dimensionField, metric) => {
  if (
    dimensionField === WIDGET_DIMENSION_FIELDS.publishDate &&
    metric.measure.field === V3_METRIC_FIELDS.userTags
  ) {
    return 'TAG';
  }

  return {
    [WIDGET_DIMENSION_FIELDS.publishDate]: 'SEARCH',
    [WIDGET_DIMENSION_FIELDS.facebook]: 'FACEBOOK',
    [WIDGET_DIMENSION_FIELDS.twitter]: 'TWITTER',
    [WIDGET_DIMENSION_FIELDS.pinterest]: 'PINTEREST',
    [WIDGET_DIMENSION_FIELDS.reddit]: 'REDDIT',
    [WIDGET_DIMENSION_FIELDS.sentiment]: 'SENTIMENT',
    [WIDGET_DIMENSION_FIELDS.domainAuthority]: 'IMPACT_SCORE',
    [WIDGET_DIMENSION_FIELDS.country]: 'COUNTRY',
    [WIDGET_DIMENSION_FIELDS.state]: 'STATE/PROVINCE',
    [WIDGET_DIMENSION_FIELDS.city]: 'CITY',
    [WIDGET_DIMENSION_FIELDS.mediaType]: 'MEDIA_TYPE',
    publisher_url: 'PUBLISHER_URL',
  }[dimensionField];
};

export const getDimensionMaxBuckets = (dimensionField, metric) => {
  if (dimensionField === 'publisher_url') {
    return 5;
  } else if (
    dimensionField === WIDGET_DIMENSION_FIELDS.publishDate &&
    metric.measure.field === V3_METRIC_FIELDS.userTags
  ) {
    return 3;
  }
};

export const getValidMetrics = (activeMetric, nonActiveMetric) => {
  if (nonActiveMetric) {
    if (
      isTotalMentionsOverTime([nonActiveMetric]) ||
      (isShareOfVoice([nonActiveMetric]) &&
        nonActiveMetric.visualizationType === undefined)
    ) {
      return [
        V3_METRIC_FIELDS.sessions,
        V3_METRIC_FIELDS.mobileReadership,
        V3_METRIC_FIELDS.desktopReadership,
      ];
    } else if (
      isReadershipOverTime([nonActiveMetric]) ||
      (isReadershipShareOfVoice([nonActiveMetric]) &&
        nonActiveMetric.visualizationType === undefined)
    ) {
      const metrics = [
        V3_METRIC_FIELDS.sessions,
        V3_METRIC_FIELDS.articleMention,
      ];
      if (
        nonActiveMetric.measure?.field === V3_METRIC_FIELDS.mobileReadership
      ) {
        metrics.push(V3_METRIC_FIELDS.desktopReadership);
      } else if (
        nonActiveMetric.measure?.field === V3_METRIC_FIELDS.desktopReadership
      ) {
        metrics.push(V3_METRIC_FIELDS.mobileReadership);
      }
      return metrics;
    } else if (nonActiveMetric.measure?.field === V3_METRIC_FIELDS.sessions) {
      return [
        V3_METRIC_FIELDS.articleMention,
        V3_METRIC_FIELDS.mobileReadership,
        V3_METRIC_FIELDS.desktopReadership,
      ];
    }
    return [];
  } else {
    return Object.values(V3_METRIC_FIELDS);
  }
};

//check used for v3 only
export const getValidGroupByOptions = ({ metric, selectedItemsCount }) => {
  if (selectedItemsCount > 1) {
    return [GROUP_BY_TYPES.dataSource]; // only groupBy that supports multiple searches
  }

  const visualizationType = metric.visualizationType;

  if (!visualizationType) {
    switch (metric.measure?.field) {
      case V3_METRIC_FIELDS.articleMention:
        return [
          GROUP_BY_TYPES.country,
          GROUP_BY_TYPES.dataSource,
          GROUP_BY_TYPES.stateProvince,
          ...(userHasDevFeatureFlag(DEV_FEATURES.cityWidgetV3)
            ? [GROUP_BY_TYPES.city]
            : []),
          GROUP_BY_TYPES.sentiment,
        ];
      case V3_METRIC_FIELDS.mobileReadership:
      case V3_METRIC_FIELDS.desktopReadership:
        return [GROUP_BY_TYPES.dataSource];
      case V3_METRIC_FIELDS.userTags:
        return [GROUP_BY_TYPES.publishDate];
      default:
        return [];
    }
  }

  let groupByOptions = [];
  if (visualizationType === VISUALIZATION_TYPES.map) {
    groupByOptions = [GROUP_BY_TYPES.country];
  } else if (metric.measure?.field === V3_METRIC_FIELDS.totalPosts) {
    groupByOptions = [GROUP_BY_TYPES.posts];
  } else if (
    visualizationType === VISUALIZATION_TYPES.area ||
    visualizationType === VISUALIZATION_TYPES.line
  ) {
    groupByOptions = [GROUP_BY_TYPES.dataSource];
  } else if (visualizationType === VISUALIZATION_TYPES.pie) {
    if (metric.measure?.label === 'SEO_IMPACT_VALUE_BUCKET') {
      groupByOptions = [GROUP_BY_TYPES.domainAuthority];
    } else {
      groupByOptions = [GROUP_BY_TYPES.sentiment, GROUP_BY_TYPES.dataSource];
    }
  } else if (visualizationType === VISUALIZATION_TYPES.horizontalBar) {
    if (
      metric.measure?.field === V3_METRIC_FIELDS.aggregateReadership ||
      metric.measure?.field === V3_METRIC_FIELDS.adEquivalency
    ) {
      groupByOptions = [GROUP_BY_TYPES.mediaType];
    } else if (metric.measure?.field === V3_METRIC_FIELDS.impactScore) {
      groupByOptions = [GROUP_BY_TYPES.outlet];
    } else if (
      metric.measure?.field === V3_METRIC_FIELDS.articleMention &&
      metric.measure?.operation === 'SUM'
    ) {
      groupByOptions = [GROUP_BY_TYPES.social];
    } else if (metric.measure?.label === 'SUB_SEARCH') {
      groupByOptions = [GROUP_BY_TYPES.dataSource];
    } else {
      groupByOptions = [
        GROUP_BY_TYPES.stateProvince,
        ...(userHasDevFeatureFlag(DEV_FEATURES.cityWidgetV3)
          ? [GROUP_BY_TYPES.city]
          : []),
      ];
    }
  } else if (
    visualizationType === VISUALIZATION_TYPES.articleList ||
    visualizationType === VISUALIZATION_TYPES.table
  ) {
    groupByOptions = [GROUP_BY_TYPES.publishDate];
  }
  return groupByOptions;
};

export const isValidV3SearchType = type =>
  SEARCH_DATA_SOURCE_TYPES.includes(type);

export const getKeyMessageDataSources = metric => {
  return metric.dataSources.filter(
    ds => ds.sourceType === DATA_SOURCE_TYPES.childSearch,
  );
};

export const getCurrentWidgetDataSourceType = metrics => {
  const isKeyMessagesWidget =
    isKeyMessages(metrics) && DATA_SOURCE_TYPES.parentSearch;
  const isTagWidget = isTag(metrics) && DATA_SOURCE_TYPES.tag;
  const isSocialSearchWidget =
    isSocialSearch(metrics) && DATA_SOURCE_TYPES.socialSearch;
  const isSearchWidget =
    !isTagWidget &&
    !isSocialSearchWidget &&
    isSearch(metrics) &&
    DATA_SOURCE_TYPES.search;

  const isImpactTotalConversionsWidget =
    isTrendingConversions(metrics) && DATA_SOURCE_TYPES.impactSearch;

  return (
    isImpactTotalConversionsWidget ||
    isKeyMessagesWidget ||
    isSearchWidget ||
    isSocialSearchWidget ||
    isTagWidget
  );
};

export const getDataSectionSourceType = metrics => {
  if (isTag(metrics)) return DATA_SOURCE_TYPES.tag;
  if (isImpactSearch(metrics)) return DATA_SOURCE_TYPES.impactSearch;
  if (isSocialSearch(metrics)) return DATA_SOURCE_TYPES.socialSearch;

  return DATA_SOURCE_TYPES.search;
};

export const getWidgetSource = metric => {
  const source =
    metric && metric.measure.label === V3_METRIC_LABELS.conversions
      ? DATA_SOURCES.cidImpact
      : DATA_SOURCES.trendkiteElasticSearch;
  return source;
};

export const convertWidgetFormToV3Widget = widgetForm => {
  const { title, primaryMetric, secondaryMetric } = widgetForm;
  const metrics = [];

  if (primaryMetric) {
    const metric = createMetricFromForm(primaryMetric, widgetForm);
    metrics.push(metric);
  }
  // cannot have both a second metric AND a date range compare metric;
  if (secondaryMetric) {
    const metric = createMetricFromForm(secondaryMetric, widgetForm);
    metrics.push(metric);
  } else if (widgetForm.dateRangeCompareType) {
    const dateRangeCompareMetric = createDateRangeCompareMetric(
      metrics[0],
      widgetForm.dateRangeCompareType,
      widgetForm.customDateRangeV3,
    );
    metrics.push(dateRangeCompareMetric);
  }

  const widget = {
    title: title,
    metrics,
  };

  if (widgetForm.widgetId) {
    widget.id = widgetForm.widgetId;
  }

  return widget;
};

const buildKmDataSources = (keyMessages, dataSourcesV3) => {
  const kmDataSources = [];

  const keyMessageDataSources = keyMessages.map(km => {
    return {
      source: DATA_SOURCES.trendkiteElasticSearch,
      sourceType: DATA_SOURCE_TYPES.childSearch,
      sourceId: km?.searchId > 0 ? km.searchId : null,
      metadata: {
        sourceModel: {
          title: km.title,
          search: km.search,
          simple: km.simple,
        },
        type: DATA_SOURCE_TYPES.childSearch,
      },
    };
  });

  kmDataSources.push(...keyMessageDataSources);
  const parentSearch = dataSourcesV3.find(
    d => d.sourceType === DATA_SOURCE_TYPES.parentSearch,
  );

  kmDataSources.push({
    source: DATA_SOURCES.trendkiteElasticSearch,
    sourceType: DATA_SOURCE_TYPES.parentSearch,
    sourceId: parentSearch.sourceId,
    metadata: {
      sourceModel: {
        id: parentSearch.sourceId,
        title: parentSearch.metadata.sourceModel.title,
      },
      type: DATA_SOURCE_TYPES.parentSearch,
    },
  });

  return kmDataSources;
};

const buildAnalyticDataSources = selectedAnalyticId => {
  return [
    {
      source: DATA_SOURCES.googleAnalytics,
      sourceType: DATA_SOURCE_TYPES.analyticsIntegration,
      sourceId: selectedAnalyticId,
    },
  ];
};

const buildDataSources = (metric, dataSourcesV3) => {
  if (metric.dataSources?.length === 1 && dataSourcesV3.length === 1) {
    const metricDataSource = metric.dataSources[0];
    const formDataSource = dataSourcesV3[0];
    // if metricDataSource and form dataSource are the same, use metric to reuse correct dataSource id
    if (
      metricDataSource.source === formDataSource.source &&
      metricDataSource.sourceType === formDataSource.sourceType &&
      metricDataSource.sourceId === formDataSource.sourceId
    ) {
      return metric.dataSources;
    } else {
      return dataSourcesV3;
    }
  } else if (dataSourcesV3?.length === 1 && dataSourcesV3[0]?.id) {
    return [
      {
        source: dataSourcesV3[0].source,
        sourceType: dataSourcesV3[0].sourceType,
        sourceId: dataSourcesV3[0].sourceId,
        metadata: {
          sourceModel: {
            id: dataSourcesV3[0].metadata.sourceModel?.id,
            title: dataSourcesV3[0].metadata.sourceModel?.title,
          },
          type: dataSourcesV3[0].metadata.type,
        },
      },
    ];
  } else {
    return dataSourcesV3;
  }
};

const getV3WidgetDataSources = (widgetForm, metric) => {
  const { keyMessages, dataSourcesV3, selectedAnalyticId } = widgetForm;
  let dataSources = [];

  if (keyMessages && keyMessages.length) {
    dataSources = buildKmDataSources(keyMessages, dataSourcesV3);
  } else if (metric.measure?.field === V3_METRIC_FIELDS.sessions) {
    dataSources = buildAnalyticDataSources(selectedAnalyticId);
  } else if (
    metric.measure?.field === V3_METRIC_FIELDS.uniquePrArticles ||
    metric.measure?.field === V3_METRIC_FIELDS.prTraffic
  ) {
    // Modify current analytics data source if it's already been created to new sourceId.
    //
    const currentDataSources = buildDataSources(metric, dataSourcesV3);
    const newAnalyticDataSources = buildAnalyticDataSources(selectedAnalyticId);
    // Other two-metric widgets with analytics use one dataSource per metric, whereas this widget uniquely needs two for
    // each metric. So there's a need to check for that already existing second dataSource, since the code elsewhere
    // makes assumptions about how many dataSources there should be per metric (one).  If two dataSources already
    // exist (i.e. this is an edit, not a create), merge the dataSources for that metric.
    //
    if (currentDataSources.length === 2) {
      dataSources = currentDataSources.map(s =>
        s.source === GOOGLE_ANALYTICS_TYPE &&
        newAnalyticDataSources[0].source === GOOGLE_ANALYTICS_TYPE
          ? { ...s, sourceId: newAnalyticDataSources[0].sourceId }
          : s,
      );
    } else {
      dataSources = [...currentDataSources, ...newAnalyticDataSources];
    }
  } else {
    dataSources = buildDataSources(metric, dataSourcesV3);
  }

  return dataSources;
};

const createMetricFromForm = (metric, widgetForm) => {
  return {
    id: metric.id,
    visualizationType: metric.visualizationType,
    dateRangeType: widgetForm.dateRangeType,
    startDate: widgetForm.startDate,
    endDate: widgetForm.endDate,
    isDateRangeCompare: false,
    measure: metric.measure,
    dimensions: metric.dimensions,
    dataSources: getV3WidgetDataSources(widgetForm, metric),
  };
};

export const createDateRangeCompareMetric = (
  mainMetric,
  dateRangeCompareType,
  customDateRange,
) => {
  return {
    ...mainMetric,
    measure: {
      ...mainMetric.measure,
      id: null,
    },
    dimensions: mainMetric.dimensions.map(x => {
      return {
        ...x,
        id: null,
      };
    }),
    dataSources: mainMetric.dataSources.map(x => {
      return {
        ...x,
        id: null,
      };
    }),
    isDateRangeCompare: true,
    visualizationType:
      mainMetric.visualizationType === VISUALIZATION_TYPES.area
        ? VISUALIZATION_TYPES.line
        : mainMetric.visualizationType,
    dateRangeCompareType: dateRangeCompareType,
    dateRangeType: customDateRange
      ? DATE_RANGE_KEYS.CUSTOM
      : DATE_RANGE_KEYS.TRAILING_90,
    startDate: customDateRange?.startDate,
    endDate: customDateRange?.endDate,
    id: null,
  };
};

export const convertWidgetFormToMetrics = widgetForm => {
  const { primaryMetric, secondaryMetric } = widgetForm;
  const widgetMetrics = [];
  if (primaryMetric) {
    const metric = createMetricFromForm(primaryMetric, widgetForm);
    widgetMetrics.push(metric);
  }
  // cannot have both a second metric AND a date range compare metric;
  if (secondaryMetric) {
    const metric = createMetricFromForm(secondaryMetric, widgetForm);
    widgetMetrics.push(metric);
  } else if (widgetForm.dateRangeCompareType) {
    const dateRangeCompareMetric = createDateRangeCompareMetric(
      widgetMetrics[0],
      widgetForm.dateRangeCompareType,
      widgetForm.customDateRangeV3,
    );
    widgetMetrics.push(dateRangeCompareMetric);
  }
  return widgetMetrics;
};

export const hasUnsharedDataSources = dataSources =>
  dataSources.some(({ shared }) => !shared);

/*
 * //TODO: add new metric in the widget response: widgetType.
 * To be able to filter by widget type when we have the BE response
 * For now this is working only for widgets with unique widget metric label (conversions is one of those)
 */
export function getIsNewWidgetDataLabel(metrics, intl) {
  const widgetV3Data = WIZARD_CHART_OPTIONS(intl)?.filter(item => {
    const itemMetrics = item?.metrics || [];
    if (itemMetrics.length > 0 && (metrics || []).length > 0) {
      return item?.metrics[0]?.measure?.label === metrics[0]?.measure?.label;
    }
    return false;
  });

  return widgetV3Data[0]?.isNewWidget || false;
}
