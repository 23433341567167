// doing fully qualified import to support usage across workspaces
import {
  DEV_FEATURES,
  FEATURES,
  USER_ROLES,
} from 'trendkite-front-end-app/src/constants/constants';

import { createCanUseRule } from './createCanUseRule';

// Code to "what", not "why"
// https://wiki.cision.com/pages/viewpage.action?pageId=1103724675
//
// This file is a collection of things a user can do, and encapsulates the business rules of the why/how.
// App show/hide logic should consume these instead of checking Feature Flags directly.

export const getCanUseCampaigns = createCanUseRule({
  // having access to Campaigns itself is a factor of having access to at least one of
  // the things inside of Campaigns.
  someFeatures: [FEATURES.connect, FEATURES.monitoring, FEATURES.storyHub],
  // Connect Basic is an "anti" feature flag that restricts certain features:
  // See: https://jira.cision.com/browse/EVER-6448
  notFeatures: [FEATURES.connectBasic],
});

export const getCanUseInfluencersFuzzyMatching = createCanUseRule({
  someDevFeatures: [DEV_FEATURES.influencersFuzzyMatching],
});

export const getCanUseInfluencersPhoneticMatching = createCanUseRule({
  someDevFeatures: [DEV_FEATURES.influencersPhoneticMatching],
  notDevFeatures: [DEV_FEATURES.influencersFuzzyMatching],
});

export const getCanUseMonitoringSearch = createCanUseRule({
  allFeatures: [FEATURES.monitoring],
});

export const getCanUseLists = createCanUseRule({
  allFeatures: [FEATURES.connect],
});

export const getCanUseAnalyticsDashboards = createCanUseRule({
  allFeatures: [FEATURES.monitoring],
});

export const getCanUseMonitoringReports = createCanUseRule({
  allFeatures: [FEATURES.monitoring],
});

export const getCanUseNewsletters = createCanUseRule({
  allFeatures: [FEATURES.monitoring],
});

export const getCanUseMonitoringAlerts = createCanUseRule({
  allFeatures: [FEATURES.monitoring],
});

export const getCanUseStories = createCanUseRule({
  allFeatures: [FEATURES.storyHub],
});

export const getCanUseEmails = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.emailAnnouncementsInCampaigns],
});

export const getCanUseEmailAnnouncements = createCanUseRule({
  allFeatures: [FEATURES.connect],
});

export const getCanUsePinPointContacts = createCanUseRule({
  allFeatures: [FEATURES.pinpointContacts],
  notFeatures: [FEATURES.connect],
});

export const getCanUseBrandwatchEmbedded = createCanUseRule({
  allFeatures: [FEATURES.brandwatchEmbeddedApplication],
});

export const getCanUseLargeNears = createCanUseRule({
  allFeatures: [FEATURES.largeNears],
});

export const getCanUsePrivateContacts = createCanUseRule({
  someFeatures: [FEATURES.connect],
  notFeatures: [FEATURES.connectBasic],
});

export const getCanUseAudienceTypeFilter = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.audienceTypeFilter],
});

export const getCanUseSuggestionsFECaching = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.influencersSuggestionFECaching],
});

export const getCanUseSuggestionsIHubCaching = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.influencersSuggestionIHubCaching],
});

export const getCanUseAllMentionsNewDrilldown = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.allMentionsNewDrilldown],
});

export const getCanUseInfluencersSearchHomeBeta = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.influencersSearchHomeBeta],
});

export const getCanuseNewDrilldownLayoutV2 = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.drilldownLayoutV2],
});

export const getCanUseChurnZeroTagging = createCanUseRule({
  someDevFeatures: [DEV_FEATURES.addChurnZeroTagging],
});

export const getCanUseListImport = createCanUseRule({
  allRoles: [USER_ROLES.contactListImporter],
  allDevFeatures: [DEV_FEATURES.devListImport],
});

export const getCanUseListImportV2 = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.devListImportV2],
  notFeatures: [FEATURES.connectBasic],
});

export const getIsUserInCisionDomain = email => {
  const domains = [
    'cision.com',
    'bulletinintelligence.com',
    'prime-research.com',
    'prnasia.com',
    'gorkana.com',
    'prnewswire.fr',
    'prnewswire.com.br',
    'prnewswire.com',
    'multivu.com',
    'prnewswire.com.mx',
    'vocus.com',
    'businessintelligence.com',
    'argus-presse.fr',
    'bulletinhealthcare.com',
    'cedrom-sni.com',
    'unmetric.com',
    'falcon.io',
    'trendkite.com',
    'brandwatch.com',
  ];
  if (email) {
    for (let i = 0; i < domains.length; i++) {
      if (email.endsWith(`@${domains[i]}`)) {
        return true;
      }
    }
  }
  return false;
};

export const getIsAmericanAccount = gcid => {
  return gcid > 2000000 && gcid < 5000001; // USA range
};

export const getIsCanadianAccount = gcid => {
  return gcid > 5000000 && gcid < 82600000001; // Canada range
};

export const getIsEmeiaAccount = gcid => {
  return gcid > 82600000000 && gcid < 92100000001; // EMEIA range
};

export const getIsFrenchAccount = gcid => {
  return gcid > 92100000000; // France range
};

export const getCanUseChurnZeroByAccountType = accountTypeId => {
  const demoAccounts = [
    2, // demonstration
    3, // beta
    5, // employee home
    6, // testing
    7, // training
  ];
  return demoAccounts.includes(accountTypeId);
};

export const getCanUseSocialMonitoringIntegration = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.socialMonitoringIntegration],
});

export const getCanUseBrandwatchUpsellLinkCanada = createCanUseRule({
  allFeatures: [
    FEATURES.brandwatchUpsellLink,
    FEATURES.canadianAccount,
    FEATURES.monitoring,
  ],
  notFeatures: [FEATURES.brandwatchEmbeddedApplication, FEATURES.unionMetrics],
});

export const getCanUseAdminManagement = createCanUseRule({
  allRoles: [USER_ROLES.adminRole],
});

export const getCanUseAdminArticleManagement = createCanUseRule({
  someRoles: [
    USER_ROLES.adminRole,
    USER_ROLES.adminLiteRole,
    USER_ROLES.contentSearchAdminRole,
  ],
});

export const getCanUseAdminPages = createCanUseRule({
  someRoles: [
    USER_ROLES.adminRole,
    USER_ROLES.adminLiteRole,
    USER_ROLES.auditRole,
    USER_ROLES.contentSearchAdminRole,
  ],
});

export const getCanUseAdminRoles = createCanUseRule({
  someRoles: [USER_ROLES.adminRole, USER_ROLES.adminLiteRole],
});

export const getCanUseImpersonate = createCanUseRule({
  allRoles: [USER_ROLES.impersonatorRole],
  someRoles: [USER_ROLES.adminRole, USER_ROLES.adminLiteRole],
});

export const getCanUseCustomerAdminRole = createCanUseRule({
  someRoles: [USER_ROLES.customerAdminRole],
});

export const getCanUseBulkRemoveFromList = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.influencersBulkRemove],
});

export const getCanUseBulkUnTag = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.bulkUntag],
});

export const getCanUseDeactivateAccount = createCanUseRule({
  allRoles: [USER_ROLES.deactivateAccountRole],
});

export const getCanUseNewSearchHomeToggle = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.influencersNewSearchHomeToggle],
});

export const getCanUseDrillDownPageSizeLimiter = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.drillDownPageSizeLimiter],
});

export const getCanUseExcelFormatsOptions = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.exportArticlesListToExcel],
});

export const getCanUseEarnedSearchesV2Support = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.earnedSearchesV2Support],
});

export const getCanUseAdvancedSearchesFilterSupport = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.advancedSearchesFilterSupport],
});

export const getCanUseWelcomeHub = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.welcomeHub],
});

export const getCanUseSearchMapping = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.searchMapping],
});

export const getCanUseBulkTagging = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.bulkTagging],
});

export const getCanUseInfluencerDisplayTargetArea = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.influencerDisplayTargetArea],
});

export const getCanUseArchiveDelete = createCanUseRule({
  allDevFeatures: [DEV_FEATURES.archiveDeleteSearches],
});
