export default {
  totalMentionsDefaultTitle: {
    id: 'TOTAL_MENTIONS_DEFAULT_TITLE',
    defaultMessage: 'Total Mentions Over Time',
    description:
      'Title of widget that displays total mentions over time within dashboards',
  },
  totalMentionsDescription: {
    id: 'TOTAL_MENTIONS_DESCRIPTION',
    defaultMessage: 'How many times were you mentioned in the news?',
    description: 'Description for total mentions widget',
  },
  verifiedDailyUniqueViewersDefaultTitle: {
    id: 'IMPACT_VERIFIED_DAILY_UNIQUE_VIEWERS_DEFAULT_TITLE',
    defaultMessage: 'Impact Verified Daily Unique Viewers',
    description:
      'Title of widget that displays verified daily unique viewers within dashboards',
  },
  verifiedDailyUniqueViewersDescription: {
    id: 'VERIFIED_DAILY_UNIQUE_VIEWERS_DESCRIPTION',
    defaultMessage:
      'How many verified daily readers have engaged with the coverage?',
    description: 'Description for verified daily unique viewers widget',
  },
  keyMessagesDefaultTitle: {
    id: 'KEY_MESSAGES_DEFAULT_TITLE',
    defaultMessage: 'Key Messages',
    description:
      'Title of widget that displays data for key messages within dashboards',
  },
  keyMessagesDescription: {
    id: 'KEY_MESSAGES_DESCRIPTION',
    defaultMessage:
      'What is the distribution of specific terms in your results?',
    description: 'Description for key messages widget',
  },
  shareOfVoiceDefaultTitle: {
    id: 'SHARE_OF_VOICE_DEFAULT_TITLE',
    defaultMessage: 'Share of Voice',
    description:
      'Title of widget that displays share of voice within dashboards',
  },
  shareOfVoiceDescription: {
    id: 'SHARE_OF_VOICE_DESCRIPTION',
    defaultMessage: 'How do you compare to your competition?',
    description: 'Description for share of voice widget',
  },
  highestReadershipDefaultTitle: {
    id: 'HIGHEST_READERSHIP_DEFAULT_TITLE',
    defaultMessage: 'Highest Readership',
    description:
      'Title of widget that displays highest readership within dashboards',
  },
  highestReadershipDescription: {
    id: 'HIGHEST_READERSHIP_DESCRIPTION',
    defaultMessage: 'Which articles have the most readership?',
    description: 'Description for highest readership widget',
  },
  socialAmpDefaultTitle: {
    id: 'SOCIAL_AMP_DEFAULT_TITLE',
    defaultMessage: 'Social Amplification',
    description:
      'Title of widget that displays social shares within dashboards',
  },
  socialAmpDescription: {
    id: 'SOCIAL_AMP_DESCRIPTION',
    defaultMessage: 'How many times were mentions shared on social media?',
    description: 'Description for social amplification widget',
  },
  socialSearchDescription: {
    id: 'SOCIAL_SEARCH_DESCRIPTION',
    defaultMessage: 'How many mentions were posted on social platform?',
    description: 'Description for social search widget',
  },
  sentimentDefaultTitle: {
    id: 'SENTIMENT_DEFAULT_TITLE',
    defaultMessage: 'Sentiment',
    description: 'Title of widget that displays sentiment within dashboards',
  },
  sentimentDescription: {
    id: 'SENTIMENT_DESCRIPTION',
    defaultMessage:
      'Are mentions mostly positive, negative, or neutral in nature?',
    description: 'Description for sentiment widget',
  },
  wordCloudDefaultTitle: {
    id: 'WORD_CLOUD_DEFAULT_TITLE',
    defaultMessage: 'Word Cloud',
    description: 'Title of widget that displays a word cloud within dashboards',
  },
  wordCloudDescription: {
    id: 'WORD_CLOUD_DESCRIPTION',
    defaultMessage: 'Which keywords have the greatest prominence?',
    description: 'Description for word cloud widget',
  },
  adEquivalencyDefaultTitle: {
    id: 'AVE_DEFAULT_TITLE',
    defaultMessage: 'Ad Equivalency',
    description:
      'Title of a widget that displays ad equivalency within dashboards',
  },
  adEquivalencyDescription: {
    id: 'AVE_DESCRIPTION',
    defaultMessage:
      'How much would this coverage cost on traditional print media?',
    description: 'Description for ad equivalency widget',
  },
  aggregateReadershipDefaultTitle: {
    id: 'AGGREGATE_READERSHIP_AND_VIEWERSHIP_TITLE',
    defaultMessage: 'Aggregate Readership & Viewership',
    description:
      'Title of a widget that displays aggregate readership within dashboards',
  },
  aggregateReadershipDescription: {
    id: 'AGGREGATE_READERSHIP',
    defaultMessage: 'How many people potentially saw my coverage?',
    description: 'Description for aggregate readership widget',
  },
  customDefaultTitle: {
    id: 'CUSTOM_DEFAULT_TITLE',
    defaultMessage: 'Custom Widget',
    description: 'Placeholder title for a custom widget',
  },
  customDescription: {
    id: 'CUSTOM_DESCRIPTION',
    defaultMessage: 'Add any data source, metric, or chart to your widget',
    description: 'Description text for a custom widget',
  },
  topPublishersTitle: {
    id: 'TOP_PUBLISHERS_TITLE',
    defaultMessage: 'Top Publishers',
    description: 'Title of a widget that displays top publishers by impact',
  },
  topPublishersDescription: {
    id: 'TOP_PUBLISHERS_DESCRIPTION',
    defaultMessage: 'Which publishers have the highest average Article Impact?',
    description: 'Description for top publishers widget',
  },
  domainAuthorityTitle: {
    id: 'DOMAIN_AUTHORITY_WIDGET_TITLE',
    defaultMessage: 'Domain Authority',
    description:
      'Title of a widget that calculates document count (mentions) by domain authority quintile',
  },
  domainAuthorityDescription: {
    id: 'DOMAIN_AUTHORITY_WIDGET_DESCRIPTION',
    defaultMessage: 'What is the Domain Authority of my coverage?',
    description: 'Description for the domain authority widget',
  },
  internationalDefaultTitle: {
    id: 'INTERNATIONAL_DEFAULT_TITLE',
    defaultMessage: 'International',
    description: 'Title of a widget that displays total mentions by country',
  },
  internationalDescription: {
    id: 'INTERNATIONAL_DESCRIPTION',
    defaultMessage: 'Which countries have the most mentions?',
    description: 'Description for international widget',
  },
  stateProvinceDefaultTitle: {
    id: 'STATE_PROVINCE_DEFAULT_TITLE',
    defaultMessage: 'State/Province',
    description:
      'Title of a widget that displays total mentions by state/province',
  },
  stateProvinceDescription: {
    id: 'STATE_PROVINCE_DESCRIPTION',
    defaultMessage: 'Which states/Provinces have the most mentions?',
    description: 'Description for state/province widget',
  },
  cityDefaultTitle: {
    id: 'CITY_DEFAULT_TITLE',
    defaultMessage: 'City',
    description: 'Title of a widget that displays total mentions by city',
  },
  cityDefaultDescription: {
    id: 'CITY_DESCRIPTION',
    defaultMessage: 'Which cities have the most mentions?',
    description: 'Description for city widget',
  },
  prTrafficDefaultTitle: {
    id: 'PR_TRAFFIC_DEFAULT_TITLE',
    defaultMessage: 'PR Referral Traffic',
    description: 'Title of a PR Referrals widget',
  },
  prTrafficDefaultDescription: {
    id: 'PR_TRAFFIC_DESCRIPTION',
    defaultMessage: 'Traffic to your sites from earned media content',
    description: 'Description of a PR Referral Traffic widget',
  },
  totalMentionsSessionsDefaultTitle: {
    id: 'TOTAL_MENTIONS_SESSIONS_DEFAULT_TITLE',
    defaultMessage: 'Total Mentions vs. Total Sessions',
    description: 'Title of a Total Mentions vs. Total Sessions widget',
  },
  totalMentionsSessionsDescription: {
    id: 'TOTAL_MENTIONS_SESSIONS_DESCRIPTION',
    defaultMessage:
      'What is the correlation between mentions and total site traffic?',
    description: 'Description of a Total Mentions vs. Total Sessions widget',
  },
  tagWidgetTitle: {
    id: 'DASHOBARD_SIDEBAR_CHART_SELECTION_WINZARD_TAG_WIDGET_TITLE',
    defaultMessage: 'Article List',
    description: 'Title for the tag widget',
  },
  tagWidgetDescription: {
    id: 'DASHOBARD_SIDEBAR_CHART_SELECTION_WINZARD_TAG_WIDGET_DESCRIPTION',
    defaultMessage: 'Select and showcase content that is important to you.',
    description: 'Description for the tag widget',
  },
  searchCustomListTitle: {
    id: 'SEARCH_CUSTOM_LIST_TITLE',
    defaultMessage: 'Custom',
    description: 'Title of the list of custom widget searches',
  },
  recentlyUsedSearches: {
    id: 'RECENTLY_USED_SEARCHES',
    defaultMessage: 'Recently Used Searches',
    description: 'Title of the list of recently searched widgets.',
  },
  impactSearches: {
    id: 'IMPACT_SEARCHES',
    defaultMessage: 'Impact Searches',
    description: 'Title of the list of impact searches widgets.',
  },
  coverageSearches: {
    id: 'COVERAGE_SEARCHES',
    defaultMessage: 'Coverage Searches',
    description: 'Title of the list of coverage searches widgets.',
  },
  metricSelectionSubtitle: {
    id: 'METRIC_SELECTION_SUBTITLE',
    defaultMessage: 'Earned Media',
    description: 'Subtitle for metric selection wizard step',
  },
  groupBySelectionDropdown: {
    id: 'GROUP_BY_SELECTION_DROPDOWN',
    defaultMessage: 'Group By',
    description: 'Text in the dropdown on the metric selection',
  },
  filterAllSearches: {
    id: 'FILTER_ALL_SEARCHES',
    defaultMessage: 'All Searches',
    description:
      'Title of the option that allows you to see all the searches of the widgets.',
  },
  filterMySearches: {
    id: 'FILTER_MY_SEARCHES',
    defaultMessage: 'My Searches',
    description:
      'Title of the option that allows you to see only your own widget searches.',
  },
  filterSharedWithMe: {
    id: 'FILTER_SHARED_WITH_ME',
    defaultMessage: 'Shared with me',
    description:
      'Title of the option that allows you to see the widget data shared with you.',
  },
  findSearch: {
    id: 'WIDGET_WIZARD_ADD_SEARCH',
    defaultMessage: 'Find a search',
    description:
      'Title of the option that allows to find a search for a new widget in the wizard.',
  },
  gaDropdownDefaultText: {
    id: 'GOOGLE_ANALYTICS_DROPDOWN_DEFAULT_TEXT',
    defaultMessage: 'Choose.',
    description:
      'text that displays in google analytics dropdown if no analytics selected',
  },
  filterAllTags: {
    id: 'FILTER_ALL_TAGS',
    defaultMessage: 'All Tags',
    description:
      'Title of the option that allows you to see all the tags of the widgets.',
  },
  filterMyTags: {
    id: 'FILTER_MY_TAGS',
    defaultMessage: 'My Tags',
    description:
      'Title of the option that allows you to see only your own widget tags.',
  },
  findTagPlaceHolder: {
    id: 'FIND_TAG_PLACEHOLDER',
    defaultMessage: 'Find a tag',
    description: 'Search tag input placeholder',
  },
  conversionsDefaultTitle: {
    id: 'IMPACT_TOTAL_CONVERSIONS_DEFAULT_TITLE',
    defaultMessage: 'Impact Total Conversions',
    description: 'Title of widget that displays conversions within dashboards',
  },
  conversionsDescription: {
    id: 'TOTAL_CONVERSIONS_DESCRIPTION',
    defaultMessage:
      'What earned media is driving direct results for my business over time?',
    description: 'Description for conversions widget',
  },
  socialSearcDefaultTitle: {
    id: 'SOCIAL_SEARCH_DEFAULT_TITLE',
    defaultMessage: 'Social Search Widget Title',
    description:
      'Title of widget that displays social search widget within dashboards',
  },
  formSettingsTabTitle: {
    id: 'FORM_SETTINGS_TAB_TITLE',
    defaultMessage: 'Settings',
    description: 'Title of settings tab for custom chart view',
  },
  formFiltersTabTitle: {
    id: 'FORM_FILTERS_TAB_TITLE',
    defaultMessage: 'Filters',
    description: 'Title of filter tab for custom chart view',
  },
  warningSecondaryDateOnlyAllowedWithXSearchesOrFewer: {
    id: 'WARNING_SECONDARY_DATE_ONLY_ALLOWED_WITH_X_SEARCHES_OR_FEWER',
    description:
      'A warning message to inform that it is not possible to add a secondary date once the number of searches exceeds X.',
    defaultMessage: `Secondary date range is only allowed with {NUMBER_OF_SEARCHES, plural,
     one {1 search}
     other {# searches or fewer}
    }.`,
  },
};
